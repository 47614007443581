import logo from './svg/logo.svg';
import facebook from './img/social/facebook.svg';
import twitter from './img/social/twitter.svg';
// import endorsement01 from './img/endorsements/jon-ashworth.png';
// import endorsement02 from './img/endorsements/rick-middleton.png';
// import endorsement03 from './img/endorsements/melissa-march.png';
import candidateCutOut from './img/cut-out.png';
import ContactForm from './components/ContactForm/ContactForm';
import { useRef } from 'react';

const smallCssClasses = 'container-medium flex items-center min-h-500';

const textSectionCssClasses =
    'text-white p-sm bg-gradient-trans text-lg lg:text-2xl my-lg lg:m-0';

const candidateBgCssClasses = (name) => {
    return `w-full bg-candidate-${name}`;
};

function App() {
    const contactForm = useRef(null);

    // const scrollToContactForm = () => {
    //     if (contactForm.current) {
    //         contactForm.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    return (
        <div
            className={`flex flex-col ${
                process.env.NODE_ENV === 'development' ? 'debug-screens' : ''
            }`}
        >
            <header
                className={
                    'h-28 md:h-40 bg-gradient w-full flex justify-center items-center'
                }
            >
                <div className={'container-small justify-center flex'}>
                    <div className={'ml-xl'}>
                        <img
                            src={logo}
                            className="App-logo w-60 lg:w-96"
                            alt="logo"
                        />
                    </div>
                </div>
            </header>

            <div className={candidateBgCssClasses('1')}>
                <div className={smallCssClasses}>
                    <div
                        className={`${textSectionCssClasses} container-x-small`}
                    >
                        I came into politics because I believe it has the power
                        to make a real difference in people’s lives. I am proud
                        to be standing as your Labour candidate for Coalville
                        North in next May&#39;s local elections. I bring the
                        commitment, passion, and energy to fight for the issues
                        that matter most to our community.
                    </div>
                </div>
            </div>

            <div className={'bg-body w-full py-2xl container-medium'}>
                <h2 className={'heading text-primary'}>
                    {'I am proud to call Leicestershire my home'}
                </h2>

                <p>
                    Hello, my name is Rebecca Pawley, and I am proud to be
                    standing as your Labour candidate in the upcoming county
                    council elections for Coalville North.
                </p>

                <p>
                    I have spent my career dedicated to creating positive change
                    in our communities and I’ve worked tirelessly to bring
                    people together—whether they’re from charities, businesses,
                    the local council, or Parliament—to find real solutions to
                    the challenges facing some of the most vulnerable in
                    society.
                </p>

                <p>
                    I’m passionate about fairness, community, and ensuring
                    everyone has the opportunities they deserve. From leading
                    successful projects that support young people, to
                    championing collaboration across diverse groups, I’ve seen
                    how we can achieve so much when we work together.
                </p>

                <p>
                    My lived experience and strong connections to the issues
                    that matter most here—affordable housing, better local
                    services, and creating opportunities for all—mean I will
                    always put the needs of Coalville North residents first. I
                    have the energy and determination to fight for our community
                    every single day.
                </p>

                <p>
                    This May, I’ll be asking for your support to deliver a
                    stronger, fairer future for Coalville North. Let’s build it
                    together.”
                </p>

                <p className={'font-bold'}>Rebecca Pawley</p>
                {/*<div className={'my-xl text-center border-t pt-xl'}>*/}
                {/*    <h2 className={'heading text-primary text-5xl'}>*/}
                {/*        Endorsed by*/}
                {/*    </h2>*/}
                {/*</div>*/}
                {/*<div className={'grid md:grid-cols-2 lg:grid-cols-3 gap-md'}>*/}
                {/*    <div*/}
                {/*        className={*/}
                {/*            'bg-primary text-white flex justify-center  py-md'*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <img src={endorsement01} alt="Endorsement" />*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={*/}
                {/*            'bg-primary text-white flex justify-center  py-md'*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <img src={endorsement03} alt="Endorsement" />*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={*/}
                {/*            'bg-primary text-white flex justify-center py-md'*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <img src={endorsement02} alt="Endorsement" />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            {/*<div className={candidateBgCssClasses('2')}>*/}
            {/*    <div*/}
            {/*        className={*/}
            {/*            'container-medium flex justify-start items-center min-h-500'*/}
            {/*        }*/}
            {/*    >*/}
            {/*        <div*/}
            {/*            className={`${textSectionCssClasses} max-w-768 px-xl pt-xl`}*/}
            {/*        >*/}
            {/*            <h2 className={'mb-sm'}>My pledges to you</h2>*/}
            {/*            <div className={'text-lg mb-lg max-w-512'}>*/}
            {/*                <p>*/}
            {/*                    You deserve a visible and active local*/}
            {/*                    representative. I will not take a second job.*/}
            {/*                    Being an MP will be my only focus.*/}
            {/*                </p>*/}
            {/*                <p>*/}
            {/*                    I will host frequent community meetings across*/}
            {/*                    the constituency so I can hear your views. My*/}
            {/*                    office will be in the heart of our community and*/}
            {/*                    always open to you.*/}
            {/*                </p>*/}
            {/*                <p>*/}
            {/*                    Growing up in a single parent family, I know*/}
            {/*                    what it feels like to struggle. I believe*/}
            {/*                    everyone should have the opportunity to*/}
            {/*                    progress, no matter what their background.*/}
            {/*                    Labour policies empower families and reflect*/}
            {/*                    Labour’s values of social justice and fairness.*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'bg-body w-full py-2xl container-medium'}>
                <h2 className={'heading text-primary'}>
                    {"Labour's Priorities"}
                </h2>
                <p>
                    A long term plan to get Britain’s future back: five fully
                    funded national missions built on fulfilling our first duty:
                    to protect our country - through economic stability, secure
                    borders and strong defence.
                </p>
                <ul className={'lg:text-xl'}>
                    <li className={'mb-md'}>
                        <strong>Get Britain Building Again</strong> - By
                        following strict rules that strengthen public finances
                        and by reforming planning laws, so we build more houses,
                        giga factories, windfarms, roads, labs and ports,
                        developing the skills needed to do so.
                    </li>
                    <li className={'mb-md'}>
                        <strong>Switch On Great British Energy</strong>
                        {` - By
                        switching on Great British Energy, a new publicly owned,
                        clean energy company, that ensures jobs are created here
                        in Britain and that we aren't dependent on tyrants like
                        Putin.`}
                    </li>
                    <li className={'mb-md'}>
                        <strong>Get The NHS Back On Its Feet</strong> - By
                        paying doctors and nurses overtime to work evenings and
                        weekends to cut the backlog - paid for by closing the
                        loopholes in Rishi Sunak’s non-dom plan and cracking
                        down on tax dodgers.
                    </li>
                    <li className={'mb-md'}>
                        <strong>Take Back Our Streets</strong> - By guaranteeing
                        town centre police patrols in every community to tackle
                        gangs, drug dealers and fly tippers - paid for by
                        cutting outside contractors and waste.
                    </li>
                    <li className={'mb-md'}>
                        <strong>Break Down Barriers To Opportunity</strong> - By
                        introducing free school breakfast clubs and more high
                        quality teaching with more specialist maths and science
                        teachers - paid for by removing tax breaks for private
                        schools, closing the loopholes in Rishi Sunak’s non-dom
                        plan and cracking down on tax dodgers.
                    </li>
                </ul>
            </div>
            <div className={'bg-gradient w-full pt-2xl flex'} ref={contactForm}>
                <div className={'container-medium grid lg:grid-cols-2'}>
                    <div className={'items-end hidden lg:flex'}>
                        <img src={candidateCutOut} />
                    </div>
                    <div className={'container-small pb-lg'}>
                        <div className={'flex justify-between'}>
                            <h2 className={'heading text-white mb-md'}>
                                {'Get In Touch'}
                            </h2>
                            <div
                                className={'text-white ml-xl flex items-center'}
                            >
                                <div>
                                    <a
                                        href={
                                            'https://www.facebook.com/RebeccalPawley'
                                        }
                                        rel={'noopener nofollow noreferrer'}
                                        target={'_blank'}
                                    >
                                        <img
                                            src={facebook}
                                            className="App-logo w-12 h-12"
                                            alt="logo"
                                        />
                                    </a>
                                </div>
                                <div className={'ml-sm'}>
                                    <a
                                        href={
                                            'https://twitter.com/rebecca_pawley'
                                        }
                                        rel={'noopener nofollow noreferrer'}
                                        target={'_blank'}
                                    >
                                        <img
                                            src={twitter}
                                            className="App-logo w-12 h-12"
                                            alt="logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <ContactForm />
                    </div>
                </div>
            </div>

            <div className={'bg-dark text-white text-center text-sm p-sm'}>
                &copy; Promoted by Terri Eynon, Secretary of NWLeics
                Constituency Labour Party at MRC, 85 Belvoir Road LE67 3PH
                <br />
                Hosted by IONOS Cloud Ltd, Discovery House, 154 Southgate St,
                The Docks, Gloucester GL1 2EX
                {/*<a*/}
                {/*    href={'/privacy-policy'}*/}
                {/*    rel={'noopener nofollow noreferrer'}*/}
                {/*    target={'_blank'}*/}
                {/*    className={'text-white ml-xs'}*/}
                {/*>*/}
                {/*    Privacy Policy*/}
                {/*</a>*/}
            </div>
        </div>
    );
}

export default App;
